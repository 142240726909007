import React from 'react'

import { graphql } from 'gatsby'
import {
  ContentfulRecipe,
  ContentfulSettings,
  Site,
  ContentfulRecipeConnection,
  ContentfulTechniqueConnection,
  ContentfulEvent,
  ContentfulArticle
} from '~generated/gatsby.types'
import theme from '~theme'
import { MainLayout } from '~layout'
import { buildPageMetaTags } from '~utils'
import RoughEdge from '~components/base-components/rough-edge'
import ArticleDetails from '~components/template-components/article-details'
import { ContentCarousel, SecondaryHero } from '~components/page-components'

type PageQuery = {
  site: Site
  contentfulRecipe: ContentfulRecipe
  contentfulSettings: ContentfulSettings
  allContentfulRecipe: ContentfulRecipeConnection
  allContentfulTechnique: ContentfulTechniqueConnection
  contentfulEvent: ContentfulEvent
  contentfulArticle: ContentfulArticle
}

type PageProps = {
  data: PageQuery
}

const Article = ({ data }: PageProps) => {
  const { site, contentfulArticle, contentfulSettings } = data
  const { id, title, image, categories, niceDate, suggestedContent, relatedArticles } = contentfulArticle

  return (
    <MainLayout metaTags={buildPageMetaTags(contentfulArticle, site)} contentfulSettings={contentfulSettings}>
      <SecondaryHero
        title={title}
        image={image}
        prefix={categories?.map(i => i.title).join(` • `)}
        mediaOrientation="Left"
        shortDescription={{ shortDescription: niceDate }}
      />
      <RoughEdge id={id} backgroundColor={theme.colors.white} />
      <ArticleDetails article={contentfulArticle} />
      {relatedArticles?.length ? (
        <ContentCarousel
          id="related-articles"
          title="Related Articles"
          shortDescription=""
          contents={relatedArticles?.concat(relatedArticles)}
          bgColorOverride={theme.colors.oatmealBrown}
          articleSizeOverride
        />
      ) : null}

      <ContentCarousel
        id="suggested-content"
        title="You might be interested in"
        shortDescription=""
        contents={suggestedContent || contentfulSettings?.articleSuggestedContent}
        bgColorOverride={relatedArticles ? theme.colors.white : theme.colors.oatmealBrown}
        articleSizeOverride
      />
    </MainLayout>
  )
}

export default Article

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    contentfulArticle(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      title
      slug
      categories {
        title
      }
      shortDescription {
        shortDescription
      }
      description {
        json
      }
      image {
        bynder: bynderAsset {
          fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
            ...QueryImageFluid
          }
        }
        local: localAsset {
          fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
            ...QueryImageFluid
          }
        }
      }
      niceDate: publishingDate(formatString: "dddd DD MMMM")
      suggestedContent {
        ...FragmentSuggestedContent
      }
      relatedArticles {
        ... on ContentfulArticle {
          __typename
          id
          title
          slug
          categories {
            title
          }
          shortDescription {
            shortDescription
          }
          image {
            bynder: bynderAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
            local: localAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
          }
        }
      }
    }

    contentfulSettings(locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }

    site {
      ...FragmentSite
    }
  }
`
