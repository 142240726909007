import styled from 'styled-components'
//
import { Media } from '~utils'

export const StyledContainer = styled.div`
  background-color: ${props => props.theme.colors.white};

  ${Media({
    padding: ['4.5rem 0', null, null, null]
  })}
`

export const StyledSocialShare = styled.div`
  display: block;
  position: relative;
  ${Media({
    maxWidth: ['100%', null, '660px'],
    width: ['calc(100% - 48px)', null, '100%']
  })}
  margin: 0 auto;

  span {
    margin-bottom: 16px;
    font-family: ${props => props.theme.fonts.body};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.5px;
  }
`
