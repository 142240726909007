import React from 'react'
import { useIntl } from 'react-intl'

import { ContentfulArticle } from '~generated/gatsby.types'
import RichText from '~components/base-components/rich-text'
import { StyledContainer, StyledSocialShare } from './Styled'
import { StyledTextBlock } from '~components/page-components/text-block/Styled'
import Social from '~components/social/Social'

type ArticleDetailsProps = {
  article: ContentfulArticle
}

const ArticleDetails = ({ article }: ArticleDetailsProps) => {
  const { description } = article
  const intl = useIntl()

  return (
    <StyledContainer>
      <StyledTextBlock>
        <RichText data={description?.json} />
        <StyledSocialShare>
          <span>{intl.formatMessage({ id: 'news.share.label' })}</span>
          <Social />
        </StyledSocialShare>
      </StyledTextBlock>
    </StyledContainer>
  )
}

export default ArticleDetails
